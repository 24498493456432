.scrollArrowWrapper {
  display: block;
  width: 100%;
  height: auto;
  cursor: pointer;

  .scrollLabel {
    width: 100%;
    text-align: center !important;

    p {
      color: var(--spruce);
      white-space: nowrap;
      text-align: center !important;
    }
  }

  .scrollArrow {
    width: 30px;

    svg {
      width: 100%;
      animation: JumpArrowEffect linear 1.3s infinite;
    }
  }

  &.themeblue .scrollArrow svg path {
    stroke: var(--sky);
  }

  &.themeyellow .scrollArrow svg path {
    stroke: var(--energy);
  }

  &.themeorange .scrollArrow svg path {
    stroke: var(--fabulous);
  }
}

@keyframes JumpArrowEffect {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}
