.textSlider {
  width: 100%;
  height: auto;

  .line {
    height: 65px;
    line-height: 65px;
    font-family: var(--display);
    color: var(--shuttle);

    strong {
      color: var(--navy);
    }

    &.lineTwo .textSliderRotation {
      display: inline-block;
      position: relative;
      width: 1px;
      height: 65px;
      vertical-align: text-top;

      span {
        top: -10px;
        left: 0;
        position: absolute;
        white-space: nowrap;
        font-size: 80px;
        font-weight: bold;
        color: var(--navy);
        opacity: 0;
        transform: translate(-20%, 0);
        will-change: transform, opacity;
        transition: opacity cubic-bezier(0.25, 0.46, 0.45, 0.94) 300ms,
          transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms 300ms;

        &[class*='active'] {
          transform: translate(0, 0);
          opacity: 1;
          transition: transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 300ms 290ms,
            opacity cubic-bezier(0.25, 0.46, 0.45, 0.94) 300ms 290ms;
        }
      }

      @media screen and (prefers-reduced-motion: reduce) {
        span {
          transition: opacity cubic-bezier(0.25, 0.46, 0.45, 0.94) 300ms,
            transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms 300ms;

          &[class*='active'] {
            transition: transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms,
              opacity cubic-bezier(0.25, 0.46, 0.45, 0.94) 300ms 290ms;
          }
        }
      }
    }
  }

  h1 {
    font-weight: 300;
    color: var(--spruce);
  }

  @media screen and (max-width: 1130px) {
    h1 {
      font-size: 40px;
    }

    .line.lineTwo .textSliderRotation span {
      font-size: 70px;
    }
  }

  @media screen and (max-width: 1040px) {
    h1 {
      font-size: 32px;
    }

    .line {
      height: 40px;
      line-height: 40px;
    }

    .line.lineTwo .textSliderRotation span {
      font-size: 46px;
      top: -4px;
    }
  }

  @media screen and (max-width: 664px) {
    .line {
      height: auto;
    }

    .line.lineOne {
      text-align: center;
    }

    .line.lineTwo .textSliderRotation {
      display: block;
      width: 100%;

      span {
        top: -3px;
        display: block;
        width: 100%;
        text-align: center;
        transform: translate(0, 50%);
      }
    }
  }
}

.viewMobile {
  display: none;

  @media screen and (max-width: 664px) {
    display: inline;
  }
}

.viewNotMobile {
  display: inline;

  @media screen and (max-width: 664px) {
    display: none;
  }
}
