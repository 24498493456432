.inputWrapper {
  display: block;
  width: 100%;
  position: relative;

  label {
    position: absolute;
    line-height: 40px;
    font-size: 24px;
    font-family: var(--display);
    font-weight: 300;
    color: var(--navy);
    top: 0;
    left: 0;
    pointer-events: none;
    will-change: line-height, top;
    transition: cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
  }

  .inputUnderline {
    width: 100%;
    height: 2px;
    background: var(--sky);
  }

  input {
    display: block;
    width: 100%;
    height: 40px;
    font-weight: 300;
    font-size: 30px;
    color: var(--spruce);
    border: none;
    background-color: transparent;
    font-family: var(--display);

    &::-webkit-input-placeholder {
      font-size: 0px;
    }

    &::-moz-placeholder {
      font-size: 0px;
    }

    &:-ms-input-placeholder {
      font-size: 0px;
    }

    &:-moz-placeholder {
      font-size: 0px;
    }
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    line-height: 20px;
    top: -24px;
    color: var(--sky);
  }

  .inputErrorMessage {
    display: block;
    line-height: 30px;
    font-family: var(--content);
    font-weight: 400;
    font-size: 16px;
    color: var(--persian);
    position: absolute;
    transform-origin: top left;
    animation: ErrorAppear cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms
      forwards;

    @media screen and (max-width: 664px) {
      line-height: 1.2;
      position: static;
      padding-top: 5px;
    }
  }

  &.fieldHasErrors {
    label {
      color: var(--persian) !important;
    }

    .inputUnderline {
      background-color: var(--persian);
    }
  }

  &:not(.fieldHasErrors) input:not(:placeholder-shown):not(:focus) {
    // & + label {
    //   color: var(--atlantis);
    // }

    & ~ .inputUnderline {
      background-color: var(--atlantis);
    }
  }
}

@keyframes ErrorAppear {
  from {
    transform: translateY(4px) rotate(3deg);
    opacity: 0;
  }

  to {
    transform: translateY(0) rotate(0);
    opacity: 1;
  }
}
