.grid {
  --gap: 40px;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto auto auto;
  // grid-template-areas: 'one three two' 'four three five' 'four six five';
  grid-template-areas:
    'one two three'
    'four two six'
    'four five six';
  gap: var(--gap);

  .block {
    height: 100%;
    display: block;
    text-decoration: none;
    color: var(--goth) !important;

    .card {
      height: 100%;
      min-height: 200px;
      display: flex;
      flex-flow: column wrap;
      background-color: var(--white);
      position: relative;
      cursor: pointer;

      .image {
        flex: 1;
        margin-bottom: 15px;
        overflow: hidden;

        img {
          margin: 0;
          padding: 0;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform-origin: center center;
          filter: grayscale(100%);
          will-change: filter, transform;
          transition: filter cubic-bezier(0.77, 0, 0.175, 1) 300ms,
            transform cubic-bezier(0.77, 0, 0.175, 1) 300ms;
        }
      }

      .content {
        h5 {
          margin: 0 0 10px 0;
        }

        p.contentSnippet {
          margin: 0;
          margin-bottom: 15px;
          color: var(--shuttle);
        }

        p.contentDate {
          padding-top: 2px;
          color: var(--regent);
        }
      }

      &:hover {
        .image img {
          filter: grayscale(0);
          transform: scale(1.1) rotate(3deg);
        }
      }
    }
  }

  .one {
    grid-area: one;
    padding-bottom: calc(var(--gap) + 20px);
  }

  .two {
    grid-area: two;
    padding-bottom: calc(var(--gap) + 20px);
  }

  .three {
    grid-area: three;
    // padding-top: calc(var(--gap) + 20px);

    .card .image {
      min-height: 200px;
    }
  }

  .four {
    grid-area: four;
  }

  .five {
    grid-area: five;
  }

  .six {
    padding-top: var(--gap);
    grid-area: six;
  }

  @media screen and (max-width: 975px) {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% + var(--gap));

    .one,
    .two,
    .three,
    .four,
    .five,
    .six {
      padding: 0;

      .card {
        display: block;

        .image {
          min-height: auto;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .block {
      flex: auto;
      min-height: auto;
      min-width: 200px;
      margin: 0 var(--gap) var(--gap) 0;

      &:nth-child(6n + 1) {
        width: calc(40% - var(--gap));
      }

      &:nth-child(6n + 2) {
        width: calc(60% - var(--gap));
      }

      // row 2

      &:nth-child(6n + 3) {
        width: calc(55% - var(--gap));
      }

      &:nth-child(6n + 4) {
        width: calc(45% - var(--gap));
      }

      &:nth-child(6n + 5) {
        width: calc(40% - var(--gap));
      }

      &:nth-child(6n + 6) {
        width: calc(60% - var(--gap));
      }
    }
  }

  @media screen and (max-width: 650px) {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: auto;

    .block {
      width: 100% !important;
    }
  }
}

@keyframes slideIN {
  from {
    opacity: 0;
    transform: translateY(-5px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
