.footer {
  width: 100%;
  background: var(--goth);
  overflow: hidden;
  position: relative;
  z-index: 200;

  .footerLinks {
    .footerLogo {
      width: 100%;
      max-width: 240px;
    }

    .footerSocial {
      display: flex;
      align-items: center;
      justify-content: center;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;

        li {
          width: 20px;
          height: 20px;

          svg {
            width: 100%;
            height: 100%;
          }

          &:hover svg path {
            fill: var(--breeze);
          }
        }
      }
    }

    .footerSitemap {
      display: flex;
      justify-content: center;
      align-items: center;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;

        li a {
          color: var(--white);
          text-decoration: none;
          line-height: 30px;

          &:hover {
            color: var(--breeze);
          }
        }
      }
    }
  }

  .footerLegal {
    background: var(--white);

    .footerLegalInner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .footerCopyright {
        font-family: var(--content);
        font-weight: 400;
        font-size: 18px;
        color: var(--regent);
      }

      .footerCopyrightLinks ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        align-items: center;

        li {
          padding-left: 24px;
          position: relative;

          a {
            font-family: var(--content);
            font-weight: 400;
            font-size: 18px;
            color: var(--regent);
            text-decoration: none;
          }

          &::before {
            content: '';
            position: absolute;
            left: 12px;
            top: 50%;
            height: 18px;
            width: 1px;
            background: var(--regent);
            transform: translate(-50%, -50%);
          }

          &:nth-child(1) {
            padding-left: 0;

            &::before {
              display: none;
            }
          }
        }
      }

      @media screen and (max-width: 1090px) {
        .footerCopyright,
        .footerCopyrightLinks ul li a {
          font-size: 16px;
        }
      }

      @media screen and (max-width: 1040px) {
        display: block;

        .footerCopyright {
          text-align: center;
          padding-bottom: 15px;
        }

        .footerCopyrightLinks {
          text-align: center;

          ul {
            justify-content: center;
            flex-flow: row wrap;
          }
        }
      }
    }
  }

  li {
    position: relative;
    cursor: pointer;
  }
}
