.contactPage {
  height: auto;
  // overflow: hidden;

  .dropUsALine {
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    position: relative;
    overflow: hidden;

    .dots {
      position: absolute;
      height: auto;
      width: 180px;
      top: 10px;
      right: -30px;

      svg {
        display: block;
        width: 100%;
      }

      @media screen and (max-width: 664px) {
        display: none;
      }
    }
  }

  .contactUsSection {
    display: flex;
    align-items: center;
    padding-bottom: 90px;

    address {
      display: block;
      padding: 35px 0;
      font-family: var(--display);
      font-style: normal;
      font-size: 26px;
      line-height: 30px;
      font-weight: 300;
      color: var(--goth);
    }

    div {
      flex: 1;
      max-width: 50%;
    }

    @media screen and (max-width: 768px) {
      display: block;

      div {
        width: 90%;
        max-width: 450px;
        margin: auto;
      }

      div:first-child {
        padding-bottom: 30px;
      }

      div:last-child {
        margin: 30px auto auto auto;
      }
    }
  }
}
