.inputWrapper {
  display: block;
  width: 100%;
  height: auto;
  position: relative;

  .checkboxInput {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 20;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  .checkboxTileWrapper {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    min-height: 80px;
    display: flex;
    align-items: center;
    background: var(--white);
    border: 1px solid var(--sky);
    border-radius: 5px;
    will-change: transform;
    transition: transform ease-in-out 300ms;

    .checkboxTileLabel {
      padding: 20px 0;
      padding-right: 46px;
      padding-left: 20px;
    }

    .checkboxTileCheck {
      width: 16px;
      height: 16px;
      position: absolute;
      border: 1px solid #8c969c;
      border-radius: 2px;
      right: 10px;
      top: 10px;

      span {
        --checkfillcolor: transparent;

        position: absolute;
        will-change: transform, opacity;
        transition: transform cubic-bezier(0.68, -0.55, 0.265, 1.55) 300ms,
          opacity cubic-bezier(0.68, -0.55, 0.265, 1.55) 300ms;
        opacity: 0;
      }

      span {
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;
        background-color: var(--checkfillcolor);
        border-radius: 1px;
        transform: scale(0);
      }

      span {
        &.blue {
          --checkfillcolor: var(--sky);
        }

        &.yellow {
          --checkfillcolor: var(--energy);
        }
      }
    }
  }

  .checkboxInput:checked + .checkboxTileWrapper {
    span {
      transform: scale(1);
      opacity: 1;
    }
  }

  .checkboxInput:focus + .checkboxTileWrapper {
    transform: scale(1.01);
  }
}
