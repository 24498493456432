*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 18px;
  padding: 0;
  margin: 0;
}
