.caseStudyPage {
  min-height: calc(100vh - 391px);
  display: block;
  max-width: 1140px;
  width: 90%;
  margin: auto;

  header {
    width: 100%;
    height: auto;
  }

  .articleWrapper {
    width: 100%;
    position: relative;
    padding-top: 25px;
    padding-bottom: 55px;

    nav {
      position: absolute;
      top: 20px;
      left: 0;
      width: 170px;

      @media screen and (max-width: 768px) {
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        border-top: solid 2px var(--concrete);
      }

      @media screen and (max-width: 664px) {
        position: static;
      }
    }

    article {
      max-width: calc(100% - 500px);
      width: 90%;
      margin: auto;
      padding-top: 0;

      p {
        margin-bottom: 1.4rem;
      }

      img {
        width: calc(100%);
        margin: 35px 0;
        display: block;
        z-index: 700;
        background-color: rgba(33, 33, 33, 0.6);
        backdrop-filter: blur(10px);
      }

      figure {
        margin: 0;
        display: block;
        padding-bottom: 25px;

        img {
          margin: 0 !important;
          display: block;
        }

        iframe {
          display: block;
          width: 100%;
          height: auto;
          max-height: 300px;
        }

        &.float {
          max-width: 300px;
          float: left;
          padding: 20px 35px 20px 0;

          img {
            width: 100%;
            margin: 0 auto;
          }

          & + blockquote {
            &::after {
              display: none;
            }
          }
        }
      }

      .contentBlockHeader {
        h2 {
          display: inline-block;
          border-bottom: solid 5px var(--energy);
        }
      }

      .contentBlockHeader,
      .contentBlockArticles {
        display: block;
        margin: auto;
        max-width: calc(650px);
      }

      .contentBlock {
        display: block;
        margin: auto;
        max-width: calc(650px);

        blockqoute {
          display: block;
        }

        h2 {
          font-family: var(--content);
          font-style: normal;
          font-weight: 300;
          font-size: 48px;
          line-height: 65px;
          color: var(--navy);
        }

        h3 {
          font-family: var(--content);
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: 38px;
          color: var(--goth);
          margin-bottom: 10px;
        }

        img {
          width: 100%;
          max-width: 900px;
          display: block;
          margin: auto;
          margin-top: 15px;
        }

        iframe {
          display: block;
          width: 100%;
          height: 500px;
        }

        ul {
          list-style: none;
          display: flex;
          flex-flow: column nowrap;
          padding: 0;
          margin: 0;

          li {
            padding-left: 24px;
            position: relative;
            font-size: 18px;
            line-height: 20px;
            font-family: var(--content);
            padding-bottom: 15px;

            &::before {
              content: '';
              display: block;
              position: absolute;
              left: 6px;
              top: 5px;
              width: 10px;
              height: 10px;
              background-color: var(--sky);
              border-radius: 50%;
            }
          }
        }
      }

      @media screen {
        max-width: calc(100% - 250px);
        margin-right: 0;
      }

      @media screen and (max-width: 768px) {
        position: static;
        max-width: unset;
        width: 100%;
      }
    }
  }
}

.articleLoader {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    .logo {
      position: relative;
      height: 40px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;

      span {
        width: 10px;
        height: 100%;
        background-color: var(--navy);
        border-radius: 2px;
        transform-origin: center center;

        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            animation: Loader linear 450ms infinite;
            animation-delay: #{100 * $i}ms;
          }
        }

        &.dot {
          height: 10px;
          background-color: var(--energy);
        }
      }
    }

    .label {
      padding-top: 20px;
      text-align: center;
      font-family: var(--display);
      color: var(--navy);
    }
  }
}

@keyframes Loader {
  0%,
  50%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0, 2px, 0);
  }

  75% {
    transform: translate3d(0, -2px, 0);
  }
}
