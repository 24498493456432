h1,
h2,
h3,
h4,
h5,
h6,
p {
  all: unset;
  display: block;
  text-align: left;
  color: var(--navy);

  &.greyed {
    color: var(--spruce);
  }
}

h1,
.h1 {
  font-family: var(--content);
  font-size: 56px;
  font-weight: 200;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
}

h2,
.h2 {
  font-family: var(--content);
  font-weight: 300;
  font-size: 48px;

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
}

h3,
.h3 {
  font-family: var(--content);
  font-weight: 700;
  font-size: 32px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}

h4,
.h4 {
  font-family: var(--content);
  font-weight: 400;
  font-size: 24px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

h5,
.h5 {
  font-family: var(--content);
  font-weight: 700;
  font-size: 18px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

p,
.p {
  font-family: var(--content);
  font-weight: 400;
  color: var(--goth);
  font-size: 18px;
}

blockquote {
  all: unset;
  display: block;
  font-family: var(--display);
  width: calc(100% - 120px);
  margin: auto;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  color: var(--spruce);
  position: relative;
  padding: 50px 0;

  &::before,
  &::after {
    font-family: var(--display);
    color: var(--energy);
    font-size: 100px;
    font-weight: 700;
    position: absolute;
  }

  &::before {
    content: '“';
    left: -60px;
    top: 50px;
  }

  &::after {
    content: '”';
    right: -60px;
    top: 70px;
  }
}
