.confetti-item {
  width: 14px;
  height: 14px;
  background: black;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -7px;
  opacity: 0;
  display: block;
  border-radius: 4px;
}

// .cannot-hover a {
//   pointer-events: none !important;
// }
