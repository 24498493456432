kbd {
  line-height: 30px;
  padding: 0 5px;
  min-width: 30px;
  background-color: var(--sky);
  color: var(--white);
  font-weight: normal;
  font-size: 18px;
  font-family: var(--content);
  display: inline-block;
  text-align: center;
  border-radius: 4px;
}
