button.loadMoreButton {
  display: block;
  width: 100%;
  height: 50px;
  cursor: pointer;
  background-color: var(--concrete);
  border-radius: 8px;
  border: none;
  font-size: 18px;
  font-family: var(--display);
  font-weight: 800;
  color: var(--navy);
  position: relative;
  will-change: color, height;
  transition: color cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms,
    height cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;

  span {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    visibility: hidden;

    object {
      display: block;
      width: 100%;
    }
  }

  &:hover {
    color: var(--sky);
  }

  &.isLoading {
    color: var(--concrete);
    height: 70px;

    span {
      visibility: visible;
    }
  }
}
