:root {
  // Primary
  --navy: #162d3f;
  --navy-transluscent: hsla(206, 48%, 17%, 0.5);
  --sky: #4084ff;
  --breeze: #98bdff;
  --energy: #ffc700;
  --denim: #0d6eaa;

  // Secondary
  --ocean: #1486cc;
  --fabulous: #ff7259;

  // Validations
  --atlantis: #97cc1f;
  --persian: #d52b2b;

  // Tertiary
  --goth: #282c35;
  --shuttle: #5c6c79;
  --spruce: #3c474e;
  --regent: #8c969c;
  --concrete: #f2f2f2;
  --white: #ffffff;
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     // Primary
//     --navy: hsl(206, 48%, 83%);
//     --sky: hsl(219, 100%, 37%);
//     --breeze: hsl(218, 100%, 20%);
//     --energy: hsl(47, 100%, 50%);
//     --denim: hsl(203, 86%, 64%);

//     // Secondary
//     --ocean: hsl(203, 82%, 56%);
//     --fabulous: hsl(9, 100%, 33%);

//     // Validations
//     --atlantis: hsl(78, 74%, 54%);
//     --persian: hsl(0, 67%, 50%);

//     // Tertiary
//     --goth: hsl(222, 14%, 82%);
//     --shuttle: hsl(207, 14%, 48%);
//     --spruce: hsl(203, 13%, 73%);
//     --regent: hsl(203, 7%, 42%);
//     --concrete: hsl(0, 0%, 5%);
//     --white: hsl(0, 0%, 0%);
//   }
// }
