.chipWrapper {
  display: inline-block;
  line-height: 40px;
  border-radius: 8px;
  box-shadow: inset 0px 2px 0px rgba(255, 255, 255, 0.8);
  background: var(--concrete);
  color: var(--goth);
  padding: 0 10px;
  font-weight: 500;
  font-size: 16px;
  will-change: background, box-shadow;
  transition: cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;

  &:hover {
    background: rgba(152, 190, 255, 0.5);
    box-shadow: inset 0px 2px 0px rgba(152, 190, 255, 0.1);
    transform: translateY(-2px);
  }
}
