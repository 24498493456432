.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--navy-transluscent);
  backdrop-filter: blur(5px);
  z-index: 999;
  padding: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
  }

  span {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 4px transparent;
    border-top-color: var(--sky);
    border-bottom-color: var(--energy);
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px;
    animation: LoadImage infinite 1000ms linear;
  }

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 40px;
    right: 20px;
    width: 50px;
    height: 4px;
    background-color: #ffffff;
    cursor: pointer;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  @media screen and (max-width: 664px) {
    padding: 90px 20px;
  }
}

@keyframes LoadImage {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
