h1.display-light {
  font-family: var(--display);
  font-size: 100px;
  font-weight: 300;
  color: var(--shuttle);

  @media screen and (max-width: 664px) {
    font-size: 46px;
  }
}

h1.display-bold {
  font-family: var(--display);
  font-size: 100px;
  font-weight: 700;
  color: var(--navy);

  @media screen and (max-width: 664px) {
    font-size: 46px;
  }
}

h2.display-light {
  font-family: var(--display);
  font-size: 72px;
  font-weight: 300;
  color: var(--shuttle);

  @media screen and (max-width: 664px) {
    font-size: 38px;
  }
}

h2.display-bold {
  font-family: var(--display);
  font-size: 72px;
  font-weight: 700;
  color: var(--navy);

  @media screen and (max-width: 664px) {
    font-size: 38px;
  }
}

h3.display-light {
  font-family: var(--display);
  font-size: 50px;
  font-weight: 300;
  color: var(--shuttle);

  @media screen and (max-width: 664px) {
    font-size: 32px;
  }
}

h3.display-bold {
  font-family: var(--display);
  font-size: 50px;
  font-weight: 700;
  color: var(--navy);

  @media screen and (max-width: 664px) {
    font-size: 32px;
  }
}

p.display {
  font-family: var(--display);
  font-size: 26px;
  color: var(--shuttle);
  font-weight: 300;

  @media screen and (max-width: 664px) {
    font-size: 20px;
  }
}
