.wrapper {
  display: block;

  .legalNav {
    margin-bottom: 50px;

    .button {
      margin-right: 10px !important;

      a {
        padding-right: 24px;
      }
    }

    .activeNavButton {
      border-color: var(--breeze);
      border-width: 4px;
    }
  }
}

.updateItem {
  margin-top: -10px;
  padding-bottom: 10px;
}
