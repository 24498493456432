ul.informationList {
  list-style: none;
  padding: 20px 0;
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 170px;
  height: auto;

  li.informationItem {
    display: block;
    padding-bottom: 20px;

    strong,
    span {
      display: block;
      font-family: var(--content);
      font-style: normal;
      font-size: 18px;
      line-height: 25px;
      color: var(--shuttle);
    }

    strong {
      color: var(--navy);
    }

    a {
      display: block;
      width: 100%;

      img {
        display: block;
        width: 100%;
        max-width: 170px;
      }
    }
  }

  &[data-float='true'] {
    position: fixed;
    top: 70px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
