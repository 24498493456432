.photoGridWrapper {
  display: block;
  width: 100%;
  height: auto;

  .photoGridRow {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 30px;
    margin-bottom: 30px;
    height: 70vh;

    .photoItem {
      overflow: hidden;
      position: relative;
      cursor: pointer;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.45);
        z-index: 9;
        transform: translateY(100%);
        opacity: 0;
        will-change: transform, opacity, filter;
        transition: cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;
        transition-delay: 0ms;
        backdrop-filter: grayscale(75%);
      }

      .info {
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 10;

        .social {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          height: 40px;
          padding-right: 10px;

          li {
            width: 35px;
            height: 35px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: var(--navy);
            cursor: pointer;

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;

              svg {
                display: block;
                width: 100%;

                path {
                  fill: var(--navy);
                }
              }
            }

            &:hover {
              background-color: var(--navy);
              color: var(--white);

              a svg path {
                fill: var(--white);
              }
            }
          }
        }

        h3,
        h5,
        .social {
          transform: translateY(40px);
          opacity: 0;
          will-change: transform, opacity;
          transition: cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;
          transition-delay: 0ms;
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        will-change: transform, opacity;
        transition: transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 400ms,
          opacity cubic-bezier(0.25, 0.46, 0.45, 0.94) 400ms;
      }

      img:nth-child(1) {
        transform-origin: bottom right;
      }

      img:nth-child(2) {
        transform-origin: bottom left;
        transform: translateY(-100%);
      }

      @media screen and (pointer: fine) {
        &:hover {
          img:nth-child(1) {
            transform: translateY(50%);
          }

          img:nth-child(2) {
            transform: translateY(0);
          }

          .info {
            h3,
            h5,
            .social {
              transform: translateY(0);
              opacity: 1;
            }

            h3 {
              transition-delay: 10ms;
            }

            h5 {
              transition-delay: 60ms;
            }

            .social {
              transition-delay: 110ms;
            }
          }

          &::after {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }

    .photoItem:nth-child(1) {
      grid-area: one;

      &::after {
        background: linear-gradient(
          to top,
          white 10%,
          rgba(255, 255, 255, 0.9) 20%,
          rgba(255, 255, 255, 0.45)
        );
      }
    }

    .photoItem:nth-child(2) {
      grid-area: two;

      &::after {
        background: linear-gradient(
          to top,
          white 15%,
          rgba(255, 255, 255, 0.9) 30%,
          rgba(255, 255, 255, 0.45)
        );
      }
    }

    .photoItem:nth-child(3) {
      grid-area: three;

      &::after {
        background: linear-gradient(
          to top,
          white 15%,
          rgba(255, 255, 255, 0.9) 30%,
          rgba(255, 255, 255, 0.45)
        );
      }
    }

    &:nth-child(odd) {
      grid-template-columns: 2fr 1fr;
      grid-template-areas: 'one two' 'one three';
    }

    &:nth-child(even) {
      grid-template-columns: 1fr 2fr;
      grid-template-areas: 'two one' 'three one';
    }
  }

  @media screen and (max-width: 1040px), (pointer: coarse) {
    .photoGridRow {
      display: flex;
      height: auto;

      .photoItem {
        flex: 1;
        height: auto;
        max-width: calc(33.33333% - 20px);

        .info {
          position: static;
          padding: 15px 0;

          h3 {
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            color: var(--navy);
          }

          h3,
          h5,
          .social {
            transform: translateY(0);
            opacity: 1;
          }
        }

        img {
          position: static;
          width: 100%;
          height: auto;

          &:nth-child(2) {
            display: none;
          }
        }

        &:nth-child(2) {
          margin: 0 30px;
        }
      }
    }
  }

  @media screen and (max-width: 830px) {
    .photoGridRow {
      display: block;

      .photoItem {
        max-width: 400px;
        margin: 0 auto 40px auto !important;
      }
    }
  }
}

.loadingPage {
  height: 60vh;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}
