.mediumArticleCard {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: auto;
  align-items: stretch;
  cursor: pointer;
  color: var(--goth) !important;

  .mediumCardImage {
    width: 500px;
    height: 500px;
    position: relative;
    margin-right: 50px;
    box-shadow: 0px 4px 30px rgba(80, 80, 80, 0.1);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      will-change: transform;
      transition: transform cubic-bezier(0.68, -0.55, 0.265, 1.55) 300ms;
    }

    &:hover img {
      transform: scale(1.1);
    }

    &.featuredImage {
      &::after,
      &::before {
        position: absolute;
        display: block;
        display: none;
      }

      &::before {
        content: 'Featured';
        z-index: 10;
        font-family: var(--content);
        font-weight: 300;
        color: var(--white);
        transform: rotate(-45deg);
        top: 50px;
        left: 13px;
        font-size: 30px;
      }

      &::after {
        content: '';
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 200px 200px 0 0;
        border-color: var(--energy) transparent transparent transparent;
      }
    }
  }

  .mediumCardContent {
    flex: 1;
    max-width: calc(100% - 550px);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    .cardDate {
      display: inline-block;
      color: var(--regent);
      font-size: 16px;
      line-height: 22px;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: calc(100%);
        position: absolute;
        bottom: -1px;
        height: 2px;
        border-radius: 2px;
        background-color: var(--sky);
      }
    }

    .cardDescription {
      width: 100%;
      flex: 1;
      padding-bottom: 20px;

      p {
        color: var(--shuttle);
      }
    }

    .chipsList {
      list-style: none;
      padding: 30px 20px;
      margin: 0;
      display: flex;
      flex-flow: row wrap;

      li {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 1040px) {
    display: block;
    max-width: 500px;
    margin: auto;

    .mediumCardContent {
      width: 100%;
      max-width: unset;
      display: block;

      .cardDate {
        padding-top: 30px;
      }

      .chipsList {
        padding: 30px 15px;
      }
    }
  }

  @media screen and (max-width: 664px) {
    max-width: unset;
    width: 100%;

    .mediumCardImage {
      width: 100%;
      height: auto;
      padding-top: 100%;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
