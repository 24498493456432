.largeArticleCardWrapper {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  text-decoration: none;
  padding-left: 70px;
  padding-inline-start: 70px;
  padding-bottom: 150px;
  padding-block-end: 150px;
  color: var(--goth) !important;

  .cardImage {
    width: 100%;
    height: auto;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.08), -5px -5px 6px var(--concrete);
    background: var(--white);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      will-change: transform;
      transition: transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
      display: block;

      &[class*='mobile'],
      &[class*='tablet'] {
        display: none;
      }

      @media screen and (max-width: 1040px) {
        &[class*='mobile'],
        &[class*='desktop'] {
          display: none;
        }

        &[class*='tablet'] {
          display: block;
        }
      }

      @media screen and (max-width: 768px) {
        &[class*='tablet'],
        &[class*='desktop'] {
          display: none;
        }

        &[class*='mobile'] {
          display: block;
        }
      }
    }
  }

  .cardContent {
    position: absolute;
    max-height: 600px;
    width: 100%;
    max-width: 500px;
    background: var(--white);
    border-radius: 30px;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);

    p {
      color: var(--shuttle);
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-inline-start: 0;
    padding-bottom: 120px;
    padding-block-end: 120px;

    .cardContent {
      position: relative;
      width: 100%;
      max-width: 520px;
      left: 50%;
      transform: translate(-50%, -100px);
    }
  }

  @media screen and (max-width: 664px) {
    padding-bottom: 0;
    padding-block-end: 0;

    .cardContent {
      transform: translate(-50%, -45px);

      button {
        display: block;
        margin: auto;
      }
    }
  }

  .dots {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 200px;
    width: 100%;
  }
}
