.map {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: auto;
    will-change: transform, filter;
    transition: transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms,
      filter cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;
    transform-origin: center center;
    transform: scale(1);
    filter: grayscale(100%);
  }

  &:hover img {
    transform: scale(1.2);
    filter: grayscale(0%);
  }
}
