// Text Alignments
.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

// Margins
$margins: (
  (0, 0),
  (1, 7px),
  (2, 12px),
  (3, 15px),
  (4, 20px),
  (5, 30px),
  (6, 45px)
);

@each $i, $p in $margins {
  .margin-all-#{$i} {
    margin: $p;
  }

  .margin-inline-#{$i} {
    margin-left: $p;
    margin-right: $p;
    margin-inline: $p;
  }

  .margin-block-#{$i} {
    margin-top: $p;
    margin-bottom: $p;
    margin-block: $p;
  }

  .margin-inline-start-#{$i} {
    margin-left: $p;
    margin-inline-start: $p;
  }

  .margin-inline-end-#{$i} {
    margin-right: $p;
    margin-inline-end: $p;
  }

  .margin-block-start-#{$i} {
    margin-top: $p;
    margin-block-start: $p;
  }

  .margin-block-end-#{$i} {
    margin-bottom: $p;
    margin-block-end: $p;
  }

  .padding-all-#{$i} {
    padding: $p;
  }

  .padding-inline-#{$i} {
    padding-left: $p;
    padding-right: $p;
    padding-inline: $p;
  }

  .padding-block-#{$i} {
    padding-top: $p;
    padding-bottom: $p;
    padding-block: $p;
  }

  .padding-inline-start-#{$i} {
    padding-left: $p;
    padding-inline-start: $p;
  }

  .padding-inline-end-#{$i} {
    padding-right: $p;
    padding-inline-end: $p;
  }

  .padding-block-start-#{$i} {
    padding-top: $p;
    padding-block-start: $p;
  }

  .padding-block-end-#{$i} {
    padding-bottom: $p;
    padding-block-end: $p;
  }
}

.margin-all-auto {
  margin: auto;
}

.margin-inline-auto {
  margin-left: auto;
  margin-right: auto;
  margin-inline: auto;
}

.margin-block-auto {
  margin-top: auto;
  margin-bottom: auto;
  margin-block: auto;
}

.margin-inline-start-auto {
  margin-left: auto;
  margin-inline-start: auto;
}

.margin-inline-end-auto {
  margin-right: auto;
  margin-inline-end: auto;
}

.margin-block-start-auto {
  margin-top: auto;
  margin-block-start: auto;
}

.margin-block-end-auto {
  margin-bottom: auto;
  margin-block-end: auto;
}
