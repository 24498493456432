.galleryWrapper {
  display: block;

  .imageInner {
    padding-top: 35px;
    width: 100%;

    .galleryRow {
      width: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr;
      gap: 30px;
      margin-bottom: 30px;
      height: 50vh;

      .photoItem {
        overflow: hidden;
        position: relative;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          margin: 0;
          background-color: #ffffff !important;
          transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
        }

        .captions {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 20px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(255, 255, 255, 0);
          transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

          h5 {
            text-align: center;
            transform: translateY(110%);
            opacity: 0;
            transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
          }
        }

        &:hover {
          img {
            opacity: 0.5;
            filter: blur(3px);
            transform: scale(1.06);
          }

          .captions {
            background-color: rgba(255, 255, 255, 0.45);

            h5 {
              transform: translateY(0);
              opacity: 1;
            }
          }
        }
      }

      .photoItem:nth-child(1) {
        grid-area: one;
      }

      .photoItem:nth-child(2) {
        grid-area: two;
      }

      .photoItem:nth-child(3) {
        grid-area: three;
      }

      &:nth-child(odd) {
        grid-template-columns: 5fr 3fr;
        grid-template-areas: 'one two' 'one three';
      }

      &:nth-child(even) {
        grid-template-columns: 3fr 5fr;
        grid-template-areas: 'two one' 'three one';
      }

      &.noGrid {
        display: block;
      }
    }

    @media screen and (max-width: 1040px), (pointer: coarse) {
      padding-top: 50px;

      .galleryRow {
        display: block;
        height: auto;
        width: 100%;
        max-width: 650px;
        margin: auto;

        .photoItem {
          display: block;
          flex: 1;
          height: auto;
          width: 100%;

          img {
            position: static;
            width: 100%;
            height: auto;
          }

          .captions {
            position: static;
            width: 100%;
            height: auto;

            h5 {
              opacity: 1;
              transform: translateY(0);
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }

  img {
    background-color: #ffffff;
    background: none;
  }
}
