.CookieNotice {
  position: fixed;
  right: 65px;
  bottom: 50px;
  width: 100%;
  max-width: 450px;
  background: var(--white);
  border: 5px solid var(--breeze);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  z-index: 300;

  .CookieNoticeImage {
    width: 100%;
    height: auto;
    height: 80px;
    position: relative;

    img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 160px;
      height: 160px;
    }
  }

  .CookieNoticeContent {
    padding: 0 30px 15px 30px;

    h6 {
      font-weight: bold;
    }

    p {
      font-size: 14px;
    }

    h6,
    p {
      font-family: var(--display);
    }

    .CookieNoticeActions {
      display: flex;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 20px;
    }
  }
}
