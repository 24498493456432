.loaderWrapper {
  width: 100%;
  height: 154px;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    .logo {
      position: relative;
      height: 40px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;

      span {
        width: 10px;
        height: 100%;
        background-color: var(--navy);
        border-radius: 2px;
        transform-origin: center center;

        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            animation: Loader linear 450ms infinite;
            animation-delay: #{100 * $i}ms;
          }
        }

        &.dot {
          height: 10px;
          background-color: var(--energy);
        }
      }
    }

    .label {
      padding-top: 20px;
      text-align: center;
      font-family: var(--display);
      color: var(--navy);
    }
  }

  .spinner {
    width: 150px;
    height: 150px;

    object {
      fill: none;
      display: block;
      width: 100%;
    }
  }
}

@keyframes Loader {
  0%,
  50%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0, 2px, 0);
  }

  75% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes Spinner {
  to {
    transform: rotate(360deg);
  }
}
