.contactFormWizard {
  width: 100%;
  height: auto;
  height: 850px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .formWrapper {
    height: 90%;
    background: linear-gradient(180deg, #f9f9f9 0%, #ffffff 100%);
    box-shadow: 10px 40px 60px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    position: relative;
    overflow: hidden;

    .formTriangles {
      width: 120px;
      position: absolute;
      right: -20px;
      top: -20px;

      img {
        width: 100%;
      }
    }

    .backButton {
      position: absolute;
      top: 20px;
      left: calc((100% - 800px) / 2);
      padding-left: 10px;
      text-transform: uppercase;
      font-family: var(--content);
      font-weight: 500;
      cursor: pointer;

      span {
        position: absolute;
        top: 50%;
        left: 0;
        border: solid 2px var(--sky);
        width: 8px;
        height: 8px;
        border-right: none;
        border-bottom: none;
        margin-top: -4px;
        transform: rotate(-45deg);
      }
    }

    .step {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate3d(0, 100%, 0);

      .formInner {
        width: 90%;
        max-width: 800px;

        .stepTitle {
          width: 100%;
          height: auto;
          padding-bottom: 30px;

          h4 {
            padding-top: 22px;
            font-weight: 400;
            font-size: 24px;
            color: var(--navy);

            &.blueLabel {
              color: var(--sky);
              font-weight: 300;
            }
          }

          p {
            color: var(--regent);
            font-size: 18px;
          }
        }

        .formInputWrapper {
          padding-bottom: 20px;

          .checkList {
            display: flex;
            flex-flow: row wrap;
            list-style: none;
            padding: 0;
            margin: 0;

            .checkItem {
              flex-basis: 50%;
              max-width: calc(50% - 15px);
              margin-bottom: 20px;

              &:nth-child(2n + 1) {
                margin-right: 15px;
              }

              &:nth-child(2n + 2) {
                margin-left: 15px;
              }
            }

            @media screen and (max-width: 768px) {
              display: block;

              .checkItem {
                max-width: unset;
                margin-left: 0 !important;
                margin-right: 0 !important;
                margin-bottom: 12px;
              }
            }
          }

          .phoneInput {
            display: flex;
            align-items: center;

            .country {
              width: 190px;
              margin-right: 15px;
              display: flex;
              align-items: center;

              .codeIcon {
                width: 20px;
                min-width: 20px;
                margin-top: -6px;
                font-size: 18px;
                color: var(--navy);
              }
            }

            .value {
              flex: 1;
            }

            @media screen and (max-width: 664px) {
              display: block;

              .country {
                width: 100%;
                margin-right: 0;
                margin-bottom: 40px;
              }
            }
          }

          .phoneInput:first-of-type {
            padding-top: 45px;
            padding-bottom: 30px;
          }

          .phoneInput:last-of-type {
            padding-bottom: 30px;
          }
        }

        .formFooter {
          .formProgressBar {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            padding-top: 10px;
            padding-bottom: 30px;

            ul.formProgressBarInner {
              list-style: none;
              margin: 0;
              padding: 0;
              display: flex;
              align-items: stretch;
              height: 10px;

              li.formProgressStep {
                background: var(--white);
                border: 1px solid rgba(140, 150, 156, 0.2);
                border-radius: 20px;
                flex: 1;
                margin: 0 3px;

                &.stepIsActive {
                  background: var(--energy);
                }
              }

              li.formProgressStep:first-child {
                margin-left: 0;
              }

              li.formProgressStep:first-child {
                margin-right: 0;
              }
            }

            .formProgressBarCount {
              position: absolute;
              top: 10px + 12px;
              font-size: 18px;
              color: var(--regent);
              font-family: var(--content);
            }
          }

          .formButtons {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .buttons {
              p {
                padding-top: 15px;
                display: block;
                color: var(--regent);
                text-align: center;
              }
            }
          }
        }
      }

      &.stepIn {
        animation: SlideIn 500ms ease-in-out 0ms forwards;
      }

      &.stepOut {
        animation: SlideOut 500ms ease-in-out 0ms forwards;
      }
    }

    .step.startStep {
      .inner {
        position: relative;
        text-align: center;
        z-index: 10;

        h2 {
          max-width: 18ch;
        }
      }

      .ball {
        position: absolute;
        width: 400px;
        height: 400px;
        z-index: 3;
        left: -150px;
        bottom: -150px;
      }

      &.stepIn {
        animation: none;
        transform: translate3d(0, 0, 0);
      }

      &.stepOut {
        transform: translate3d(0, 0, 0);
        animation: SlideOut 500ms ease-in-out 1200ms forwards;

        .ball {
          animation: BallRoll 1200ms linear 0s forwards;
        }
      }
    }

    .step:nth-child(2):not(.stepOneDone) {
      &.stepIn {
        animation-delay: 1200ms;
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: auto;
    min-height: unset;
    max-height: 850px;

    .formWrapper {
      height: auto;
      padding: 70px 0;

      .formTriangles {
        display: none;
      }

      .step {
        position: static;
        transform: none;
        width: 100%;
        height: auto;
        display: none;
        min-height: 70vh;

        &.stepIn {
          animation: FadeIn cubic-bezier(0.455, 0.03, 0.515, 0.955) 500ms
            forwards;
          display: flex;
        }

        &.stepOut {
          animation: none;
          display: none;
        }
      }

      .step.startStep {
        .ball {
          width: 350px;
          height: 350px;
          bottom: -240px;
          left: -170px;
        }
      }

      .step:nth-child(2).stepIn {
        animation-delay: 0ms;
      }
    }
  }

  @media screen and (max-width: 664px) {
    max-height: unset;

    .formWrapper {
      width: 100% !important;
    }
  }
}

@keyframes BallRoll {
  from {
    transform: rotate(0deg);
    left: -150px;
  }

  to {
    transform: rotate(700deg);
    left: calc(100% + 400px);
  }
}

@keyframes SlideOut {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes SlideIn {
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes FadeIn {
  from {
    opacity: 0;
    transform: scale(0.85);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.contactError {
  display: block;
  font-family: var(--content);
  font-weight: 400;
  font-size: 16px;
  color: var(--persian);
  transform-origin: top left;
  animation: ErrorAppear cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms forwards;
}

@keyframes ErrorAppear {
  from {
    transform: translateY(4px) rotate(3deg);
    opacity: 0;
  }

  to {
    transform: translateY(0) rotate(0);
    opacity: 1;
  }
}
