.inputWrapper {
  --fillcolor: white;
  display: inline-block;
  width: auto;
  position: relative;
  height: auto;

  .radioInput {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 10;
  }

  .radioWrapper {
    display: flex;
    align-items: center;

    .radio {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      border: solid 2px var(--regent);
      position: relative;
      overflow: hidden;

      span {
        position: absolute;
        top: 4px;
        left: 4px;
        bottom: 4px;
        right: 4px;
        border-radius: 15px;
        background-color: var(--fillcolor);
        opacity: 0;
        transform: scale(0);
        will-change: opacity, transform;
        transition: transform cubic-bezier(0.68, -0.55, 0.265, 1.55) 300ms,
          opacity cubic-bezier(0.68, -0.55, 0.265, 1.55) 300ms;
      }
    }

    .radioLabel {
      flex: 1;
      padding-left: 10px;
      padding-right: 10px;
      font-family: var(--content);
      color: var(--spruce);
      line-height: 30px;
      white-space: nowrap;
      user-select: none;
    }
  }

  &.blue {
    --fillcolor: var(--sky);
  }

  &.yellow {
    --fillcolor: var(--energy);
  }

  .radioInput:checked + .radioWrapper .radio span {
    transform: scale(1);
    opacity: 1;
  }

  .radioInput:focus + .radioWrapper .radio,
  .radioInput:hover + .radioWrapper .radio {
    border-color: var(--fillcolor);
  }
}
