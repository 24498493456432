a,
.anchor {
  font-size: inherit;
  position: relative;
  color: inherit;
  font-family: var(--content);
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: var(--breeze);
  }
}

a.with-arrow,
.anchor.with-arrow {
  color: var(--navy);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  display: inline-block;
  padding-right: 20px;
  white-space: nowrap;

  &::after,
  &::before {
    content: '';
    position: absolute;
    display: block;
    will-change: transform;
    transition: transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
  }

  &::after {
    width: 14px;
    right: 0;
    top: 50%;
    margin-top: -1px;
    height: 2px;
    background: var(--breeze);
  }

  &::before {
    width: 12px;
    height: 12px;
    border: solid 2px var(--breeze);
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    right: 0;
    top: 50%;
    margin-top: -6px;
    transform: rotate(45deg) translate(0, 0);
    border-top-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:active {
    &::before {
      transform: rotate(45deg) translate(5px, -5px);
    }

    &::after {
      transform: translateX(7px);
    }
  }

  &.orange {
    &::after {
      background: var(--fabulous);
    }

    &::before {
      border-color: var(--fabulous);
    }
  }

  &.yellow {
    &::after {
      background: var(--energy);
    }

    &::before {
      border-color: var(--energy);
    }
  }

  &.royal {
    &::after {
      background: var(--sky);
    }

    &::before {
      border-color: var(--sky);
    }
  }

  &.reverse {
    padding-right: 0;
    padding-left: 20px;

    &::after {
      left: 0;
      right: auto;
    }

    &::before {
      right: auto;
      left: 0;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: var(--breeze) !important;
      border-left-color: var(--breeze) !important;
    }

    &:active {
      &::before {
        transform: rotate(45deg) translate(-5px, 5px);
      }

      &::after {
        transform: translateX(-7px);
      }
    }
  }
}

a.with-underline,
.anchor.with-underline {
  color: var(--denim);
  text-transform: uppercase;
  font-weight: 700;
  font-size: inherit;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 2px;
    background: var(--denim);
    pointer-events: none;
    opacity: 0;
    transform: translateY(3px);
    will-change: opacity, transform;
    transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms,
      transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
  }

  &:hover::after {
    opacity: 1;
    transform: translateY(0);
  }

  &:active::after {
    opacity: 1;
    transform: translateY(-1px);
  }
}

a.with-arrow,
a.with-underline,
.anchor.with-arrow,
.anchor.with-underline {
  &.no-transform {
    text-transform: none;
  }
}
