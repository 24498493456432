.solutionsPage {
  height: auto;

  header.solutionsHeader {
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(rgba(244, 244, 244, 0.3), #ffffff);
    padding-top: 60px;

    h1 strong {
      color: var(--navy);
    }

    h2 .dotChanger {
      transform: translateY(-15px);
    }

    [class*='container'] {
      padding-bottom: 30px;
    }

    @media screen and (max-width: 768px) {
      padding-top: 35px;
    }
  }

  article {
    .solutionsList {
      list-style: none;
      padding: 45px 0;
      margin: 0;
      display: block;
      max-width: 1000px;
      margin: auto;

      .solution {
        margin-bottom: 50px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.hasBorder {
  border-top: dashed 1px var(--regent);
}
