.noJavascriptBanner {
  position: fixed;
  bottom: 20px;
  right: 0;
  width: auto;
  display: block;
  z-index: 999;
  opacity: 0;
  animation: EnterDesktop 500ms 2000ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
    forwards;

  input[type='checkbox'] {
    position: fixed;
    bottom: 230px;
    right: 8px;
    width: 40px;
    height: 40px;
    z-index: 99999;
    cursor: pointer;
    opacity: 0;
  }

  .noJavascriptBannerInner {
    padding: 20px;
    padding-right: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: hsla(0, 0%, 100%, 0.95);
    width: 500px;
    height: 276px;
    backdrop-filter: blur(5px);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    border: solid 2px var(--energy);
    border-right: none;
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: stretch;

    .header {
      position: relative;
      width: 50px;

      .close {
        position: absolute;
        width: 26px;
        height: 26px;
        border: solid var(--goth) 2px;
        left: 15px;
        top: 16px;
        transform-origin: center center;
        transform: rotate(45deg) translate(-50%, 0);
        border-radius: 50%;
      }
    }

    .content {
      padding-right: 20px;
      max-width: calc(100% - 50px);
    }
  }

  input:checked {
    display: none;
  }

  input:checked + .noJavascriptBannerInner {
    display: none;
  }

  @media screen and (max-width: 664px) {
    top: 0;
    left: 0;
    bottom: auto;
    left: auto;
    animation: EnterMobile 500ms 2000ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
      forwards;

    input[type='checkbox'] {
      top: 14px;
      right: 12px;
      bottom: auto;
    }

    .noJavascriptBannerInner {
      width: 100%;
      border-radius: 0;
      border-right: solid 2px var(--energy);
      background-color: var(--white);
      flex-flow: column nowrap;
      height: auto;
      padding: 20px;

      .header {
        height: 26px;
        width: 100%;
        margin-bottom: 10px;

        .close {
          right: 0;
          left: auto;
          top: 0;
          transform: rotate(45deg);
        }
      }

      .content {
      }
    }
  }
}

@keyframes EnterDesktop {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes EnterMobile {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
