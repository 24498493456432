.articleHeader {
  display: block;
  width: 100%;
  height: auto;
  background: linear-gradient(rgba(244, 244, 244, 0.3), var(--white));

  .image {
    max-width: 1140px;
    margin: auto;

    img,
    object {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
}
