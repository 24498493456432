.inputWrapper {
  width: auto;
  display: inline-block;
  height: auto;
  position: relative;
  cursor: pointer;

  .checkboxInput {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 5;
  }

  .checkboxWrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 1;

    .checkbox {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      border: solid 2px var(--regent);
      position: relative;
      overflow: hidden;

      span.fill,
      span.tick {
        --checkfillcolor: transparent;
        position: absolute;
        will-change: transform, opacity;
        transition: transform cubic-bezier(0.68, -0.55, 0.265, 1.55) 300ms,
          opacity cubic-bezier(0.68, -0.55, 0.265, 1.55) 300ms;
        opacity: 0;
      }

      span.fill {
        top: 4px;
        left: 4px;
        bottom: 4px;
        right: 4px;
        background-color: var(--checkfillcolor);
        border-radius: 2px;
        transform: scale(0);
      }

      span.tick {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform: rotate(-45deg) scale(0);

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 18px;
          height: 10px;
          border: solid 4px var(--checkfillcolor);
          border-top: none;
          border-right: none;
          top: 50%;
          left: 50%;
          margin: -7px -9px;
          transform: rotate(-45deg);
        }
      }

      span.fill,
      span.tick {
        &.blue {
          --checkfillcolor: var(--sky);
        }

        &.yellow {
          --checkfillcolor: var(--energy);
        }
      }
    }

    .checkboxLabel {
      flex: 1;
      padding-left: 10px;
      padding-right: 10px;
      font-family: var(--display);
      color: var(--spruce);
      line-height: 30px;
      white-space: nowrap;
      user-select: none;
      width: calc(100% - 30px);
    }

    @media screen and (max-width: 768px) {
      .checkboxLabel {
        white-space: normal;
        padding: 0 10px;
      }
    }
  }

  .checkboxInput:checked + .checkboxWrapper {
    span.fill {
      transform: scale(1);
      opacity: 1;
    }

    span.tick {
      transform: rotate(0deg) scale(1);
      opacity: 1;
    }
  }

  .checkboxInput:focus + .checkboxWrapper,
  .checkboxInput:hover + .checkboxWrapper {
    &.blue .checkbox {
      border-color: var(--sky);
    }

    &.yellow .checkbox {
      border-color: var(--energy);
    }
  }

  &.smallerInput .checkboxWrapper .checkboxLabel {
    font-size: 16px;
    padding-right: 0;
  }

  &.boldLabel .checkboxWrapper .checkboxLabel {
    font-weight: bold;
  }

  &.fieldHasErrors {
    .checkboxWrapper .checkbox {
      border-color: var(--persian) !important;
    }
  }
}
