@function maxValue($v, $max) {
  @if ($v <= $max) {
    @return $max;
  }

  @return $v;
}

.navbar {
  width: 100%;
  height: 100px;
  background-color: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 201;
  will-change: height;
  transition: cubic-bezier(0.645, 0.045, 0.355, 1) 300ms;
  box-shadow: 0px 4px 40px rgba(140, 150, 156, 0.14);

  .innerWrapper {
    width: 90%;
    height: 100%;
    max-width: 1140px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .navLogo {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        display: block;
        width: 240px;
        height: auto;
      }
    }

    ul.navLinksWrapper {
      height: 100%;
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      will-change: transform, opacity;
      transition: cubic-bezier(0.645, 0.045, 0.355, 1) 300ms;

      li {
        height: 100%;
        line-height: 100px;
        position: relative;
        transition: cubic-bezier(0.645, 0.045, 0.355, 1) 300ms;
      }

      li a {
        padding: 0 14px;
        font-size: 18px;
        text-decoration: none;
        color: var(--goth);
        position: relative;
        display: block;
        font-family: var(--display);
        font-weight: normal;

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% - 24px);
          height: 4px;
          left: 50%;
          transform: translate(-50%, 4px);
          bottom: 0;
          background-color: var(--breeze);
          opacity: 0;
          will-change: transform, opacity;
          transition: transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 200ms,
            opacity cubic-bezier(0.25, 0.46, 0.45, 0.94) 200ms;
        }

        &.linkActive {
          font-weight: bold;
        }

        &.linkActive::after {
          opacity: 1;
          transform: translate(-50%, 0px);
        }

        &:hover:not(.linkActive)::after {
          opacity: 0.6;
          transform: translate(-50%, 0px);
        }
      }
    }

    .navCollapseButton {
      position: absolute;
      right: 0;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transform: scale(0);
      will-change: opacity, transform;
      transition: cubic-bezier(0.645, 0.045, 0.355, 1) 300ms;

      button {
        line-height: 45px;
        padding: 0 30px;
        border: none;
        border-radius: 20px;
        font-size: 20px;
        background: var(--energy);
        border: 1px dashed var(--white);
        box-sizing: border-box;
        box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.08),
          -5px -5px 6px var(--concrete);
        border-radius: 50px;
      }
    }

    .navMobileMenu {
      position: relative;
      z-index: 500;
      display: none;
    }
  }

  @media screen and (min-width: 769px) {
    &.isScrolled {
      height: 70px;
      box-shadow: 0px 4px 40px rgba(140, 150, 156, 0.14);

      .innerWrapper {
        ul {
          transform: translateY(-100%);
          opacity: 0;

          li {
            line-height: 90px;
          }
        }

        .navCollapseButton {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .innerWrapper .navLogo img {
      width: 200px;
    }

    .innerWrapper ul.navLinksWrapper {
      position: fixed;
      background-color: transparent;
      top: 0;
      left: 0;
      z-index: 100;
      height: 100%;
      width: 100%;
      margin: 0;
      display: none;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      li {
        width: 90%;
        max-width: 400px;
        text-align: center;
        position: relative;
        z-index: 10;
        height: auto;
        line-height: 80px;
        transition: none;
        opacity: 0;
        transform: translateY(10px);

        a {
          font-size: 32px;
          color: var(--navy);
        }

        a::after {
          display: none;
        }

        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            $value: 400 * ($i / 2);
            animation: ItemSlideUp
              ease-out
              250ms
              #{maxValue($value, 500)}ms
              forwards;
          }
        }
      }

      &::after,
      &::before {
        content: '';
        display: block;
        width: 50%;
        height: 100%;
        background-color: var(--energy);
        position: absolute;
        top: 0;
        opacity: 0;
        will-change: transform, opacity;
      }

      &::before {
        left: 0;
        transform: translate3d(0, 100%, 0);
      }

      &::after {
        right: 0;
        transform: translate3d(0, -100%, 0);
      }

      &.menuIsOpen {
        display: flex;

        &::before,
        &::after {
          animation: PanelSlideShuffle 500ms ease-out forwards;
        }
      }

      &.menuIsClosing {
        animation: MenuClose ease-in 300ms forwards;
      }
    }

    .innerWrapper .navMobileMenu {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      background-color: var(--goth);
      border-radius: 50px;
      border: solid 2px var(--goth);
      will-change: background;
      transition: background ease 500ms;

      button {
        width: 16px;
        height: 14px;
        background-color: transparent;
        border: none;
        position: relative;
        border-radius: 50%;

        span {
          display: block;
          position: absolute;
          height: 2px;
          background-color: var(--white);
          border-radius: 1px;
          top: 50%;
          right: 0;
          will-change: transform, opacity;
        }

        span:nth-child(1) {
          width: 16px;
          transform: translate3d(0, -7px, 0) rotate(0);
          transition: transform ease 250ms 0ms;
        }

        span:nth-child(2) {
          width: 12px;
          transform: translate3d(0, -50%, 0) rotate(0);
          transition: transform ease 250ms 250ms, opacity ease 250ms 250ms;
        }

        span:nth-child(3) {
          width: 14px;
          transform: translate3d(0, 5px, 0) rotate(0);
          transition: transform ease 250ms 0ms;
        }
      }

      &.buttonIsActive {
        background: transparent;

        button {
          span {
            background: var(--goth);
            transform-origin: center center;
          }

          span:nth-child(1) {
            transition: transform ease 250ms 250ms;
            transform: translate3d(0, -50%, 0) rotate(45deg);
          }

          span:nth-child(2) {
            transition: transform ease 250ms 0ms, opacity ease 250ms 0ms;
            opacity: 0;
            transform: translate3d(100%, -50%, 0) rotate(0deg);
          }

          span:nth-child(3) {
            transition: transform ease 250ms 250ms;
            width: 16px;
            transform: translate3d(0, -50%, 0) rotate(-45deg);
          }
        }
      }
    }

    &.isScrolled {
      height: 70px;
    }
  }
}

@keyframes PanelSlideShuffle {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes ItemSlideUp {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes MenuClose {
  to {
    opacity: 0;
  }
}
