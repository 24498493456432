.dot {
  display: inline-block;
  vertical-align: bottom;
  width: 16px;
  height: 16px;
  background-color: var(--energy);
  margin-left: 4px;
  transform: translateY(-2px);
  border-radius: 4px;

  @media screen and (max-width: 664px) {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin-left: 2px;
  }
}
