.policyPage {
  display: block;
  padding-top: 45px;

  article {
    section {
      padding-bottom: 30px;

      h3 {
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        display: block;
        padding: 10px 0;
        margin: 0;

        li {
          padding-left: 40px;
          line-height: 30px;
          position: relative;

          &::before {
            content: '';
            display: block;
            position: absolute;
            background-color: var(--sky);
            width: 8px;
            height: 8px;
            border-radius: 2px;
            left: 20px;
            top: calc((30px / 2) - (8px / 2));
          }
        }
      }

      p,
      ul,
      ol {
        margin-bottom: 10px;
      }
    }
  }
}

.ManageCookies {
  width: 100%;
  height: auto;
  background: linear-gradient(180deg, #f9f9f9 0%, #ffffff 100%);
  box-shadow: 10px 40px 60px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 70px 100px;
  position: relative;

  h3 {
    color: var(--sky);
    margin-bottom: 15px;
  }

  ul {
    display: block;
    width: 100%;
    height: auto;
    padding: 20px 0;
    margin: auto;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--regent);
      margin-bottom: 20px;

      .Content {
        flex: 1;
        max-width: calc(100% - 100px);
        padding-right: 35px;
      }

      .Checkbox {
        width: 100px;

        p {
          color: var(--regent) !important;
        }
      }

      &:last-child {
        border-bottom: none;
        margin-bottom: none;
      }
    }
  }

  .ButtonActions {
    .buttonInner {
      display: flex;
    }

    &.disabled {
      opacity: 0.5;
      filter: grayscale(100%);
      pointer-events: not-allowed;

      .buttonInner {
        pointer-events: none;
      }
    }
  }

  .triangles {
    position: absolute;
    top: 0;
    right: 0;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}
