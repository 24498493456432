.textHeader {
  display: block;
  width: 100%;
  position: relative;
  padding-bottom: 50px;
  
  @media screen and (min-width: 665px) {
    min-height: 250px;
  }

  .textHeaderInner {
    width: 100%;
    height: auto;

    .line {
      line-height: 92%;
    }

    .line.lineTwo {
      max-width: 10ch;

      [class*='dot'] {
        transform: translateY(-10px);

        @media screen and (max-width: 664px) {
          transform: translateY(-5px);
        }
      }
    }

    .description p {
      color: var(--shuttle);
    }
  }

  .image {
    position: absolute;
    right: 0;
    top: -50px;
    width: 220px;

    svg {
      display: block;
      width: 100%;
    }

    @media screen and (max-width: 664px) {
      top: auto;
      bottom: -100%;
      right: auto;
      left: -20px;
    }
  }
}
