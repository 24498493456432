.button {
  display: inline-block;
  background: var(--energy);
  border: 1px solid var(--white);
  box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.08), 0px 0px 0px var(--concrete),
    0px 0px 0px rgba(0, 0, 0, 0.12), inset 0px 0px 0px rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  line-height: 60px;
  padding: 0 50px 0 24px;
  cursor: pointer;
  will-change: box-shadow;
  transition: box-shadow cubic-bezier(0.455, 0.03, 0.515, 0.955) 250ms;
  text-decoration: none;

  .buttonInner {
    width: 100%;
    height: auto;
    min-width: 100px;
    position: relative;

    .buttonLabel {
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #162d3f;
    }

    .buttonArrow {
      position: absolute;
      top: 50%;
      width: 16px;
      height: 16px;
      right: -25px;
      transform: translateY(-50%);

      span {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 2px;
        background-color: var(--white);
        left: 0;
        margin-top: -1px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &::before,
        &::after {
          content: '';
          width: 70%;
          height: 2px;
          background-color: var(--white);
          position: absolute;
          display: block;
          right: 1px;
          top: 0;
          border-radius: 20px;
          transform-origin: right center;
        }

        &::before {
          transform-origin: right bottom;
          transform: rotate(45deg);
        }

        &::after {
          transform-origin: right top;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.buttonLoading {
    outline: none;
  }

  &:hover {
    box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.08), -5px -5px 6px var(--concrete),
      3px 5px 28px rgba(0, 0, 0, 0.12),
      inset 2px 2px 6px rgba(255, 255, 255, 0.2);
  }

  &:active {
    box-shadow: -5px -5px 6px var(--concrete),
      inset 2px 2px 4px rgba(0, 0, 0, 0.36);
  }

  &.blue {
    background: var(--breeze);
  }

  &.navy {
    background: var(--navy);

    .buttonInner .buttonLabel {
      color: var(--white);
    }
  }

  &.dark {
    background: var(--navy);

    .buttonInner .buttonLabel {
      color: var(--energy);
    }
  }

  &.light {
    background: var(--concrete);

    .buttonInner {
      .buttonLabel {
        color: var(--navy);
      }

      .buttonArrow span {
        background: var(--navy);

        &::before,
        &::after {
          background: var(--navy);
        }
      }
    }
  }

  &.smallButton {
    line-height: 40px;
  }

  &.pillButton {
    line-height: 40px;
    padding-right: 24px;
    background: var(--white);
    border: 1px solid #8c969c;
    box-shadow: none;
    margin-bottom: 10px;

    &:hover {
      border: 1px solid #98bdff;
      box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.08), -5px -5px 6px var(--concrete),
        3px 5px 28px rgba(0, 0, 0, 0.12),
        inset 2px 2px 6px rgba(255, 255, 255, 0.2);
    }

    .buttonLabel {
      font-size: 18px;
      text-transform: initial;
    }
  }

  @media screen and (max-width: 664px) {
    padding: 0 20px;
    line-height: 55px;

    .buttonInner .buttonArrow {
      display: none;
    }
  }

  &.noArrow {
    padding: 0 25px !important;
  }

  // @media screen and (prefers-color-scheme: dark) {
  //   box-shadow: 3px 5px 8px rgba(255, 255, 255, 0.08),
  //     0px 0px 0px var(--concrete), 0px 0px 0px rgba(255, 255, 255, 0.12),
  //     inset 0px 0px 0px rgba(255, 255, 255, 0.2);

  //   &:hover {
  //     box-shadow: 3px 5px 8px rgba(255, 255, 255, 0.08),
  //       -5px -5px 6px var(--concrete), 3px 5px 28px rgba(255, 255, 255, 0.12),
  //       inset 2px 2px 6px rgba(0, 0, 0, 0.2);
  //   }

  //   &:active {
  //     box-shadow: -5px -5px 6px var(--concrete),
  //       inset 2px 2px 4px rgba(255, 255, 255, 0.36);
  //   }
  // }
}
