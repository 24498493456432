/**
 * SETTINGS
 * Config...............Project-level config and variables
 *
 * TOOLS
 * Mixins...............Global mixins and functions.
 *
 * GENERIC
 * Reset................…and zero them out.
 * Normalizers........Normalise browser defaults…
 *
 * ELEMENTS
 * Typography.............H1–H6, P
 * Forms................Element-level form styling.
 *
 * OBJECTS
 * Layout...............Simple Layout abstraction tool.
 * List-bare............‘Unstyled’ lists: bullets and indent removed.
 * List-pair............Simple key–value pair list.
 * Pack.................Force elements to pack up into all available space.
 *
 * COMPONENTS
 * Forms................Component-level form styling.
 * Buttons..............Element-agnostic button styles.
 * Comments.............Comment styles.
 * Messaging............User feedback messaging.
 *
 * UTILITIES
 * Typography...........Utilities for manipulating text.
 * Display..............Helper classes for showing or hiding content.
 * Widths...............Width helper classes for use with our Layout system.
 *
 */

@import './theme/settings/colors';
@import './theme/settings/query-sizes';
@import './theme/settings/fonts';

@import './theme/generic/resets';
@import './theme/generic/normal';

@import './theme/elements/typography';

@import './theme/objects/containers';

@import './theme/components/anchor';
@import './theme/components/confetti';
@import './theme/components/dot';
@import './theme/components/kbd';
@import './theme/components/overlay';

@import './theme/utilities/utilities';
@import './theme/utilities/display';

::-moz-selection {
  background-color: var(--energy);
  color: #ffffff;
  border-radius: 8px;
}

::selection {
  background-color: var(--energy);
  color: #ffffff;
  border-radius: 8px;
}

.page {
  width: 90%;
  max-width: 1140px;
  margin: auto;
}
