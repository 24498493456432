.solutionPage {
  min-height: calc(100vh - 391px);
  display: block;
}

.solutionPage .headerInner {
  padding: 100px 0;

  h1 {
    padding-bottom: 10px;

    i {
      transform: translate(-15px, -23px);
    }

    &.secondLine {
      white-space: nowrap;

      span.light {
        font-weight: 300;
        color: var(--shuttle);
      }
    }
  }
}

.solutionPage .information {
  max-width: 950px;
  width: 90%;
  margin: auto;
  padding: 45px 0;

  h1 {
    margin-bottom: 15px;
  }
}

.solutionPage .contactFormWrapper {
  padding: 50px 0;
}

.pageWithNavigation {
  .pageNavigation {
    position: fixed;
    top: 70px;
    height: calc(100% - 70px);
    left: 0;
    width: 100%;
    z-index: 100;
    pointer-events: none;

    a.next,
    a.previous {
      pointer-events: all;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 80px;
      background-color: var(--energy);
      border: solid 2px #ffffff;
      will-change: width;
      transition: width ease 300ms;
      box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.08), 0px 0px 0px var(--concrete),
        0px 0px 0px rgba(0, 0, 0, 0.12),
        inset 0px 0px 0px rgba(255, 255, 255, 0.2);

      i {
        position: absolute;
        top: 50%;
        width: 18px;
        height: 2px;
        background-color: var(--white);
        margin-top: -1px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &::before,
        &::after {
          content: '';
          width: 70%;
          height: 2px;
          background-color: var(--white);
          position: absolute;
          display: block;
          right: 1px;
          top: 0;
          border-radius: 20px;
          transform-origin: right center;
        }

        &::before {
          transform-origin: right bottom;
          transform: rotate(45deg);
        }

        &::after {
          transform-origin: right top;
          transform: rotate(-45deg);
        }
      }

      span {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        line-height: 76px;
        color: #ffffff;
        transition: ease 300ms;
        white-space: nowrap;
      }

      @media screen and (min-wdth: 768px), (pointer: fine) {
        &:hover {
          width: 160px;

          span {
            opacity: 1;
          }
        }
      }

      @media screen and (max-width: 790px) {
        top: 70%;
      }

      @media screen and (max-width: 670px) {
        top: 80%;
      }
    }

    a.previous {
      left: 0;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      border-left: none;

      span {
        left: 35px;
      }

      i {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        left: 5px;
      }

      i::before,
      i::after {
        right: auto;
        left: -1px;
      }

      i::before {
        transform-origin: left bottom;
        transform: rotate(-45deg);
      }

      i::after {
        transform-origin: left top;
        transform: rotate(45deg);
      }
    }

    a.next {
      right: 0;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      border-right: none;

      span {
        right: 35px;
      }

      i {
        right: 5px;
      }
    }
  }

  .allSection {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    text-align: center;
    padding: 15px 0;
  }
}
