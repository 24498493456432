.pageHeader {
  display: block;
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  background: linear-gradient(rgba(244, 244, 244, 0.3), var(--white));

  .headerInnerWrapper {
    width: 90%;
    max-width: 1140px;
    margin: auto;
    display: flex;
    align-items: stretch;
    flex-flow: row nowrap;
    height: calc(100vh - 150px);
    max-height: 690px;

    .headerContent {
      flex: auto;
      position: relative;
      z-index: 10;
      max-width: calc(100% - 500px);

      @media screen and (max-width: 850px) {
        max-width: unset;
      }
    }

    .headerImage {
      flex-basis: 40%;
      max-width: 500px;
      position: relative;
      z-index: 9;
      display: flex;
      align-items: center;

      img,
      object {
        display: block;
        width: calc(100% + 50px);
      }
    }

    @media screen and (max-width: 1040px) {
      // flex-flow: column;
      display: block;

      .headerContent {
        flex: none;
        overflow: hidden;
        max-width: unset;
      }

      .headerImage {
        flex: 1;
        max-width: unset;
        display: flex;
        align-items: center;
        justify-content: center;

        img,
        object {
          width: auto;
          height: 100%;
          margin: auto;
          max-height: 400px;
        }
      }
    }
  }

  .headerActionLink {
    height: 50px;
    width: 90%;
    max-width: 1140px;
    margin: auto;

    @media screen and (max-width: 664px) {
      text-align: center;
    }
  }

  // @media (prefers-color-scheme: dark) {
  //   background: linear-gradient(hsla(0, 0%, 4%, 0.3), var(--white));
  // }
}
