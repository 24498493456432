.componentsPage {
  height: auto;

  header.header {
    display: block;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 5vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    h1 {
      font-size: 10vh;
      background: linear-gradient(90deg, #ffc700 0%, #ff7259 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &::after {
      content: '';
      display: block;
      width: 90%;
      height: 4px;
      max-width: 1140px;
      background: linear-gradient(90deg, #ff7259 0%, #ffc700 100%);
      position: absolute;
      bottom: 0;
    }
  }

  article {
    text-align: justify;

    section {
      padding-bottom: 70px;

      .displaySection {
        padding: 0 20px 20px 20px;
      }
    }
  }
}

hr {
  width: 50%;
  max-width: 350px;
  border: none;
  height: 4px;
  background-color: var(--spruce);
  margin: 40px auto;
}

.mapWrapper {
  max-width: 500px;
  margin: auto;
}
