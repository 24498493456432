.textareaWrapper {
  width: 100%;
  height: auto;
  position: relative;
}

.textareaWrapper textarea {
  min-height: 40px;
  display: block;
  width: 100%;
  height: 40px;
  overflow: hidden;
  resize: none;
  font-weight: 300;
  font-size: 30px;
  color: var(--spruce);
  font-family: var(--display);
  background-color: transparent;
  border: none;

  &::-webkit-input-placeholder {
    font-size: 0px;
  }

  &::-moz-placeholder {
    font-size: 0px;
  }

  &:-ms-input-placeholder {
    font-size: 0px;
  }

  &:-moz-placeholder {
    font-size: 0px;
  }
}

.textareaWrapper .inputUnderline {
  width: 100%;
  height: 2px;
  background: var(--sky);
}

.textareaWrapper.fieldHasErrors .inputUnderline {
  background-color: var(--persian);
}

.textareaWrapper .inputCount {
  position: absolute;
  line-height: 14px;
  height: 14px;
  font-size: 14px;
  bottom: -16px;
  right: 0;
  font-family: var(--content);
  color: var(--shuttle);
}

.textareaWrapper .inputErrorMessage {
  display: block;
  line-height: 30px;
  font-family: var(--content);
  font-weight: 400;
  font-size: 16px;
  color: var(--persian);
  position: absolute;
  transform-origin: top left;
  animation: ErrorAppear cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms forwards;
}

.textareaWrapper:not(.fieldHasErrors) {
  textarea:not(:placeholder-shown):not(:focus) ~ .inputUnderline {
    background-color: var(--atlantis);
  }
}

@keyframes ErrorAppear {
  from {
    transform: translateY(4px) rotate(3deg);
    opacity: 0;
  }

  to {
    transform: translateY(0) rotate(0);
    opacity: 1;
  }
}
