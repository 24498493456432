.homePage {
  height: auto;

  .headerInnerWrapper {
    padding-top: 100px;

    .buttonWrapper {
      padding-top: 50px;
    }

    @media screen and (max-width: 1040px) {
      padding-top: 40px;
    }

    @media screen and (max-width: 664px) {
      .buttonWrapper {
        padding-top: 0;
        text-align: center;
      }
    }
  }
}
