.about {
  .aboutUsHeader {
    display: block;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(rgba(244, 244, 244, 0.3), #ffffff);

    .aboutContainer {
      display: flex;
      align-items: center;

      .aboutContent {
        flex: 1;

        h2 {
          white-space: nowrap;

          @media screen and (max-width: 664px) {
            white-space: normal;
          }
        }
      }

      .aboutImage {
        flex: 1;
        max-width: 520px;
        position: relative;

        object {
          position: absolute;
          left: -30px;
          top: 50%;
          transform: translateY(-50%);
          width: calc(100% + 5vw + 50px);
        }
      }

      @media screen and (max-width: 1100px) {
        .aboutContent {
          max-width: 50%;
        }

        .aboutImage {
          max-width: 50%;

          object {
            left: 0;
            width: calc(100% + 5vw);
            transform: translateY(-10%);
          }
        }
      }

      @media screen and (max-width: 610px) {
        .aboutContent {
          max-width: unset;
        }

        .aboutImage {
          display: none;
        }
      }
    }

    h1 {
      line-height: 80%;
      white-space: nowrap;
      padding-left: 50px;

      @media screen and (max-width: 768px) {
        padding-left: 30px;
      }
    }

    p.headerP {
      padding-left: 50px;
      max-width: 45ch;
      text-align: left;

      @media screen and (max-width: 768px) {
        padding-left: 0;
      }

      @media screen and (max-width: 664px) {
        text-align: center !important;
      }
    }

    p {
      text-align: center;
    }
  }

  .aboutUsSnippet {
    height: auto;
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--goth);

    .aboutUsSnippetContent {
      font-size: 65px;
      min-height: calc(100vh - 70px);
      max-width: 700px;
      padding-top: 90px;
      padding-bottom: 70px;
      padding-left: 50px;
      will-change: opacity, transform;
      position: relative;
      z-index: 15;
      transition: cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;

      h2,
      p {
        -webkit-text-fill-color: #ffffff;
        color: #ffffff;
      }

      @media screen and (pointer: coarse) {
        padding: 50px 20px !important;
        min-height: unset;
      }
    }

    .aboutUsGame {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;

      span.paddle {
        position: absolute;
        width: 40px;
        height: 150px;
        background-color: #ffffff;

        &:nth-child(1) {
          bottom: calc(30% - 150px);
          left: 50px;
        }

        &:nth-child(2) {
          top: calc(30% - 150px);
          right: 50px;
        }
      }

      span.ball {
        position: absolute;
        width: 35px;
        height: 35px;
        background-color: var(--energy);
        border-radius: 30px;
        top: calc(30% - 75px);
        right: 150px;
      }

      .practice {
        position: absolute;
        top: 40%;
        max-width: calc(100% - 20ch);
        text-align: center;
        right: 7%;

        & > span.label {
          font-family: var(--display);
          color: var(--energy);
          display: block;
          padding-bottom: 15px;
        }
      }

      @media screen and (max-width: 1150px) {
        display: none;
      }
    }

    .pongGame {
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // padding: 0 50px;
    }

    .gamePlayHowTo {
      position: fixed;
      left: 50%;
      bottom: 20px;
      max-width: 900px;
      width: 90%;
      height: 50px;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .group {
        color: var(--white);
        font-weight: normal;
        font-family: var(--content);
      }
    }

    &:fullscreen {
      .aboutUsSnippetContent {
        opacity: 0;
      }

      .aboutUsGame {
        display: none;
      }

      .pongGame {
        display: block;
      }
    }
  }

  .fullscreenElement {
    height: 500px;
    background-color: hsl(300, 47%, 75%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: background-color cubic-bezier(0.645, 0.045, 0.355, 1) 400ms;

    .gameBall {
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      background-color: hsl(225, 73%, 57%);
      transform: translate(0px, 0px);
      transition: cubic-bezier(0.645, 0.045, 0.355, 1) 400ms 250ms;
      border-radius: 40px;
    }

    .fullscreenElementInner {
      text-align: center;
    }

    h1,
    p {
      color: hsl(300, 47%, 98%);
    }

    p {
      font-weight: 300;
    }

    &:fullscreen {
      background-color: hsl(270, 50%, 40%);

      h1,
      p {
        color: hsl(270, 50%, 80%);
      }

      .gameBall {
        width: 400px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 100%);
        border-radius: 0;
      }
    }
  }

  .paddingMobile {
    height: 50px;

    @media screen and (max-width: 664px) {
      height: 30px;
    }
  }
}

article {
  text-align: justify;
}
