.inputWrapper {
  display: block;
  width: 100%;
  height: auto;
  position: relative;

  .selectInput {
    all: unset;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    cursor: pointer;
    opacity: 0;
  }

  .selectInput:focus + .dropdownWrapper .dropdownLabel {
    color: var(--breeze);
  }

  .dropdownWrapper {
    width: 100%;
    height: 42px;
    border-bottom: solid 2px var(--sky);
    position: relative;
    z-index: 5;

    .dropdownLabel,
    .dropdownValue {
      position: absolute;
      line-height: 40px;
      font-size: 24px;
      font-family: var(--display);
      font-weight: 300;
      color: var(--navy);
      top: 0;
      left: 0;
      pointer-events: none;
    }

    .dropdownLabel {
      will-change: line-height, top;
      transition: cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;

      &.lifted {
        line-height: 20px;
        top: -22px;
        color: var(--sky) !important;
      }
    }

    .arrow {
      position: absolute;
      width: 14px;
      height: 14px;
      border: solid 2px var(--sky);
      border-radius: 2px;
      border-top: none;
      border-right: none;
      top: 50%;
      right: 2px;
      margin-top: -7px;
      transform: rotate(-45deg);
      will-change: margin-top;
      transition: ease 500ms;
    }

    .inputErrorMessage {
      display: block;
      line-height: 30px;
      font-family: var(--content);
      font-weight: 400;
      font-size: 16px;
      color: var(--persian);
      position: absolute;
      transform-origin: top left;
      bottom: -32px;
      animation: ErrorAppear cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms
        forwards;
    }
  }

  &.fieldHasErrors {
    .dropdownWrapper {
      border-bottom-color: var(--persian);

      .dropdownLabel {
        color: var(--persian) !important;
      }

      .arrow {
        border-color: var(--persian);
      }
    }
  }

  &.validField {
    .dropdownWrapper {
      border-bottom-color: var(--atlantis);

      .arrow {
        border-color: var(--atlantis);
      }
    }
  }
}

@keyframes ErrorAppear {
  from {
    transform: translateY(4px) rotate(3deg);
    opacity: 0;
  }

  to {
    transform: translateY(0) rotate(0);
    opacity: 1;
  }
}
