.detailCard {
  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: stretch;
  color: var(--goth);

  .cardImage {
    max-width: 360px;
    min-width: 360px;
    width: 360px;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .cardContent {
    flex: 1;
    max-width: calc(100% - 360px);
    padding-right: 45px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;

    h2 {
      color: var(--navy);
    }

    p {
      color: var(--shuttle);
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    max-width: 550px;
    width: 100%;
    margin: auto;

    .cardImage {
      max-width: unset;
      width: 100%;
      margin-bottom: 20px;
      min-width: unset !important;
    }

    .cardContent {
      max-width: unset;
      display: block;
    }
  }

  &:hover {
    color: var(--shuttle);
  }
}
